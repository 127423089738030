import React from "react"
import ContactForm from "../components/ContactForm"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Socials from '../components/Socials'

const Contact = () => {

  return(
    <Layout>
      <SEO title="Contact" />
      <div className="paper py-20 px-5 min-h-screen">
        <div className="container my-10 mx-auto">
          <div className="flex justify-center items-center">
            <div className="w-full md:w-1/2">
              <h1 className="text-3xl md:text-4xl leading-none pb-10 text-center">Let's Talk</h1>
              <ContactForm />
              <div className="mt-10">
                <Socials />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact